/**
 * The `beforeinstallprompt` event is fired when the browser detects that the
 * web app can be installed as a Progressive Web App (PWA).
 *
 * By calling `event.preventDefault()`, the default browser install prompt is
 * prevented from appearing.
 *
 * For more information, see {@link https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent}
 */
window.addEventListener("beforeinstallprompt", (event) =>
  event.preventDefault()
);
